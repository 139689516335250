@font-face {
  font-family: "iconfont"; /* Project id 4285122 */
  /* Color fonts */
  src: 
       url('iconfont.woff2?t=1698115194798') format('woff2'),
       url('iconfont.woff?t=1698115194798') format('woff'),
       url('iconfont.ttf?t=1698115194798') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-a-summary:before {
  content: "\e90f";
}

